import { Injectable } from '@angular/core'
import { HammerGestureConfig } from '@angular/platform-browser'
import * as Hammer from 'hammerjs'

@Injectable()
export class HammerConfigProvider extends HammerGestureConfig {
  override overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL }, // Enable all swipe directions
    pinch: { enable: true }, // Enable pinch gestures
    rotate: { enable: true }, // Enable rotate gestures
  }
}
