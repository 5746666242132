import { Observable } from 'rxjs'
import { Injector, Signal, untracked } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'

export type ToSignalState<T> = {
  [K in keyof T]: T[K] extends Observable<infer U> ? Signal<U> : never
}

export function toSignalState<T extends Record<string, Observable<unknown>>>(
  obj: T,
  injector: Injector,
): ToSignalState<T> {
  return untracked(() => {
    const result: any = {}
    const options = { injector }
    Object.entries(obj).forEach(([key, value]) => {
      if (value instanceof Observable) {
        result[key] = toSignal(value, options)
      } else {
        console.warn(`toSignalState: skipped `, { key, value })
      }
    })
    return result
  })
}
